import React, { useState, Fragment } from "react";
import HomeBottomBG1 from "../../assets/common/images/HomeBG2.png";
import ServiceSectionComp from "../../Components/ServiceSectionComp";
import Layout from "../../Components/utils/Layout";
import UIUXIMG from "../../assets/common/svgs/service/UIUXIMG.svg";
import ToolIMG from "../../assets/common/images/ToolImg.png";
import uiuxXIMG from "../../assets/common/svgs/ui-ux.svg";
import Testimonial from "../../Components/pageComponent/Testimonial";
import ServiceCard from "../../Components/ServiceCard";
import DeviderLine from "../../Components/DeviderLine";

const ServiceUIUX = () => {
  const [serviceData, setserviceData] = useState({
    DeviderTitle: "WHY IGEEK",
    Heading: "Why choose iGeek for UI/UX Design services?",
    Image: UIUXIMG,
    description:
      "Our design process begins with in-depth research to understand your target audience, business objectives, and competition. We are passionate about designing interfaces that are intuitive, visually appealing, and engaging. Our team focuses on designing interfaces that are easy to navigate, visually appealing, and offer clear value propositions to users.",
    title: "Our UI/UX Design services",

    cards: [
      {
        Image: uiuxXIMG,
        title: "User research and analysis",
        background: "bg-placebo-turquoise",
        description:
          "Our UI/UX design team conducts in-depth research to gather insights about your target audience and create designs that are tailored to their needs.",
      },
      {
        Image: uiuxXIMG,
        title: "Wireframing and prototyping",
        background: "bg-clear-skies",
        description:
          "Our UI/UX design team creates wireframes and prototypes that help you visualize and refine the design of your product or service.",
      },
      {
        Image: uiuxXIMG,
        title: "Using Latest tools",
        background: "bg-placebo-turquoise",
        description:
          "We believe that using the latest design tools and technologies is crucial to delivering high-quality designs that are visually appealing and user-friendly.",
      },
      {
        Image: uiuxXIMG,
        title: "User Testing & Validation",
        background: "bg-placebo-turquoise",

        description:
          "By conducting thorough user testing and validation, we gather valuable insights and feedback to inform the design process, ensuring your digital experiences are tailored as expected.",
      },
      {
        Image: uiuxXIMG,
        background: "bg-clear-skies",
        title: "Tools we use",
        description: "figma, xd, ai, ps, webflow",
      },
    ],
  });

  const [otherService, setotherService] = useState([
    {
      Image: ToolIMG,
      title: "Web Development",
      background: "bg-placebo-turquoise",
      description:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.",
      link: "/services-web-development",
    },
    {
      Image: ToolIMG,
      title: "Mobile app Development",
      background: "bg-clear-skies",
      description:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.",
      link: "/services-mobile-development",
    },
    {
      Image: ToolIMG,
      title: "Product Development",
      background: "bg-placebo-turquoise",
      description:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.",
      link: "/services-product-development",
    },
    {
      Image: ToolIMG,
      title: "Dedicated Developer/Team",
      background: "bg-clear-skies",
      description:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.",
      link: "/services-developer",
    },
    {
      Image: ToolIMG,
      title: "QA/Testing",
      background: "bg-placebo-turquoise",
      description:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.",
      link: "/services-testing",
    },
  ]);

  return (
    <>
      <Layout>
        <div className="">
          <div className="relative mt-[100px] flex h-screen items-center justify-center pb-[350px]">
            <div className="absolute bottom-[-5px] flex h-[344px] w-full justify-center">
              <img
                draggable="false"
                className="h-full object-cover"
                src={HomeBottomBG1}
                alt=""
              />
            </div>

            <div className="flex flex-col items-center justify-center text-center">
              <div className="HeadingText mt-[100px] max-w-[980px] max_md:px-[50px] max_sm:px-[20px]">
                Elevating User Experience with Creative UI/UX Solutions.
              </div>
              <div className="SubHeadingText mt-[40px] max-w-[858px] max_md:px-[50px] max_sm:px-[20px]">
                We believe that a well-designed user interface can transform the
                way users interact with your products or services.
              </div>
            </div>
          </div>

          <div className="m-auto w-full max-w-[1240px] max_xl:max-w-[940px] max_md:px-[50px] max_sm:px-[20px]">
            <ServiceSectionComp
              maxWidth={"max-w-[490px]"}
              serviceData={serviceData}
            />
          </div>

          {/* <div className="mt-[160px] max_md:mt-[80px]">
            <Testimonial />
          </div>

          <div className="m-auto w-full max-w-[1240px] max_xl:max-w-[940px] max_md:px-[50px] max_sm:px-[20px]">
            <div className="mt-[160px] max_md:mt-[80px]">
              <DeviderLine title="OTHERS" right="right-[75%]" />

              <div className="mt-[65px] flex flex-wrap justify-center gap-[15px] max_md:mt-[40px]">
                <div className="w-[403px] pt-[40px] text-[30px] font-bold leading-[45px] text-duskwood max_md:pt-[0px] max_md:pb-[40px] max_md:text-ExLargeSize max_md:leading-[32px] ">
                  <div className="max-w-[362px]">
                    We Provide Other Exclusive Services For Turn Your Business
                    Into A Brand
                  </div>
                </div>

                {otherService.map((cardData, index) => (
                  <Fragment key={index}>
                    <ServiceCard
                      title={cardData.title}
                      desc={cardData.description}
                      background={cardData.background}
                      readMore={cardData.link}
                    />
                  </Fragment>
                ))}
              </div>
            </div>
          </div> */}
        </div>
      </Layout>
    </>
  );
};

export default ServiceUIUX;
