import React from "react";
import HomeBottomBG1 from "../assets/common/svgs/BottomBG.svg";
import DeviderLine from "../Components/DeviderLine";
import Layout from "../Components/utils/Layout";
import VISIONIMG from "../assets/common/images/VisionImage.png";
import MISSIONIMG from "../assets/common/images/MIssionImg.png";
import MISSIONBG from "../assets/common/svgs/MissionBG.svg";
import BGIMG from "../assets/common/images/missionImage.png";
import IGBG from "../assets/common/svgs/IGBG.svg";
// import AboutEye from "../assets/common/svgs/EyeAboutus.svg";
import MissionLogo from "../assets/common/svgs/MissionLogo.svg";
import RocketIcon from "../assets/common/svgs/RocketIcon.svg";

const AboutUs = () => {
  return (
    <Layout>
      {/* HERO SECTION */}
      <div className="relative mt-[100px] flex h-screen items-center justify-center pb-[350px]">
        <div className="absolute bottom-[-5px] flex h-[344px] w-full justify-center">
          <img
            draggable="false"
            className="h-full object-cover"
            src={HomeBottomBG1}
            alt=""
          />
        </div>

        <div className="flex flex-col items-center justify-center text-center">
          <div className="HeadingText  mt-[100px] max-w-[900px] max_md:px-[50px] max_sm:px-[20px]">
            Bridging the gap between ideas and reality with software.
          </div>
          <div className="SubHeadingText mt-[40px] max-w-[890px] max_md:px-[50px] max_sm:px-[20px]">
            iGeek is a leading software development company that specializes in
            crafting bespoke software solutions for businesses of all sizes. Our
            team of expert developers, designers, and engineers is passionate
            about creating software that solves complex business challenges and
            drives success.
          </div>
        </div>
      </div>

      <div className="m-auto w-full max-w-[1240px] max_xl:max-w-[940px] max_md:px-[50px] max_sm:px-[20px]">
        {/* PART 1 */}
        <div>
          <DeviderLine title="Who Are We ?" right="right-[25%]" />
          <div className="mt-[96px] mb-[160px] flex w-full max_md:mb-[80px]  max_md:flex-col max_md:items-center max_md:gap-y-[20px] max_md:text-center max_sm:mt-[60px]">
            <div className="flex w-1/2 items-center justify-center max_md:w-[80%] ">
              <img draggable="false" src={IGBG} alt="" />
            </div>
            <div className="flex w-1/2 max-w-[584px] items-center max_md:w-full">
              <div className="w-full">
                <div className="text-[30px] font-bold text-duskwood max_md:mt-[25px] max_md:text-[18px] max_md:leading-[24px]">
                  iGeek{" "}
                </div>
                <div className="text-ExLargeSize font-semibold leading-[24px] text-bateau max_md:text-[16px] max_md:leading-[20px]">
                  Since 2018
                </div>
                <div className="mt-[14px] text-LargeSize leading-[30px] text-her-sub-heading max_md:text-SmallSize max_md:leading-[20px]">
                  At iGeek, we are a team of passionate technology enthusiasts
                  who are dedicated to providing innovative solutions to our
                  clients. Founded in 2018, our company has quickly established
                  a reputation as a reliable provider of IT services, helping
                  businesses of all sizes to leverage the latest technology to
                  achieve their goals.
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* PART 2 */}
        <div>
          <DeviderLine title="VISION" right="right-[50%]" />
          <div className="mt-[96px] mb-[120px] flex w-full flex-row-reverse max_md:mb-[80px] max_md:flex-col max_md:items-center max_md:gap-y-[20px] max_md:text-center max_sm:mt-[60px]">
            <div className="flex w-1/2 items-center justify-center max_md:w-full">
              <img draggable="false" src={MissionLogo} alt="" />
            </div>
            <div className="flex w-1/2 max-w-[584px] items-center max_md:w-full">
              <div className="w-full ">
                <div className="max-w-[350px] text-[32px] font-bold leading-[45px] text-duskwood max_xl:max-w-[86%] max_md:text-[18px] max_md:leading-[24px]">
                  Your partner in software innovation.
                </div>
                <div className="mt-[22px] text-LargeSize leading-[30px] text-her-sub-heading max_md:mt-[14px] max_md:text-SmallSize max_md:leading-[20px]">
                  We envision to be a leading provider of innovative IT
                  solutions that empower businesses to reach their full
                  potential. We believe that technology should be a
                  transformative force that simplifies business processes,
                  enhances productivity, and unlocks new opportunities for
                  growth.
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* PART 3 */}
        {/* MISSION */}

        <div>
          <DeviderLine title="Who Are We ?" right="right-[25%]" />
          <div className="mt-[96px] mb-[160px] flex w-full max_md:mb-[80px]  max_md:flex-col max_md:items-center max_md:gap-y-[20px] max_md:text-center max_sm:mt-[60px]">
            <div className="flex w-1/2 items-center justify-center max_md:w-[80%] ">
              <img draggable="false" src={RocketIcon} alt="" />
            </div>
            <div className="flex w-1/2 max-w-[584px] items-center max_md:w-full">
              <div className="w-full">
                <div className="max-w-[299px] text-[32px] font-bold leading-[45px] text-duskwood max_xl:max-w-[86%] max_md:text-[18px] max_md:leading-[24px]">
                  Good Things Take Some Time
                </div>
                <div className="mt-[14px] text-LargeSize leading-[30px] text-her-sub-heading max_md:text-SmallSize max_md:leading-[20px]">
                  Our commitment to exceptional customer service is at the core
                  of everything we do. We value our clients' trust and are
                  dedicated to building long-term relationships with them based
                  on mutual respect, transparency, and communication and we
                  strive to foster a culture of innovation and continuous
                  learning.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* MISSION */}
      {/* OLD ONE WITH ROCKET 28/11/24 */}
      {/* <div className="relative m-auto h-[570px] max-w-[1920px] max_md:h-auto">
        <div className="absolute bottom-[1px] h-[400px] w-full max_md:top-[50px] max_md:bottom-auto max_md:h-[196px]">
          <img
            draggable="false"
            className="h-full object-cover object-[63%_0%] max_xl:object-[40%_0%] max_sm:object-[30%_0%]"
            src={MISSIONBG}
            alt=""
          />
        </div>
        <div className="m-auto h-full w-full max-w-[1240px] max_xl:max-w-[940px] max_md:px-[50px] max_sm:px-[20px]">
          <DeviderLine title="Our Mission" right="right-[75%]" />
          <div className="flex h-full w-full items-center justify-end max_md:justify-center">
            <div className="w-1/2 max-w-[584px] max_md:mt-[260px] max_md:w-full">
              <div className="w-full max_md:justify-center max_md:text-center">
                <div className="max-w-[280px] text-[32px] font-bold leading-[45px] text-duskwood max_md:max-w-full max_md:text-[18px] max_md:leading-[24px]">
                  Good Things Take Some Time
                </div>
                <div className="mt-[22px] text-LargeSize leading-[30px] text-her-sub-heading max_md:mt-[14px] max_md:text-SmallSize max_md:leading-[20px]">
                  Our commitment to exceptional customer service is at the core
                  of everything we do. We value our clients' trust and are
                  dedicated to building long-term relationships with them based
                  on mutual respect, transparency, and communication and we
                  strive to foster a culture of innovation and continuous
                  learning.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}

      {/* IMG */}
      <div className="m-auto mt-[50px] w-full max-w-[1240px] select-none p-[100px] pb-0 max_xl:max-w-[940px] max_xl:pt-[50px] max_md:mt-0 max_md:px-[50px] max_md:pt-[50px] max_sm:px-[20px] max_sm:pt-[20px]">
        <img draggable="false" src={BGIMG} alt="" />
      </div>
    </Layout>
  );
};

export default AboutUs;
