import React, { useState, Fragment } from "react";
import HomeBottomBG1 from "../../assets/common/images/HomeBG2.png";
import ServiceSectionComp from "../../Components/ServiceSectionComp";
import Layout from "../../Components/utils/Layout";
import StaffAugmentationIMG from "../../assets/common/svgs/service/StaffAugmentationIMG.svg";
import ToolIMG from "../../assets/common/images/ToolImg.png";
import Testimonial from "../../Components/pageComponent/Testimonial";
import ServiceCard from "../../Components/ServiceCard";
import DeviderLine from "../../Components/DeviderLine";

import MobileIcon from "../../assets/common/svgs/Technology/mobile.svg";
import AndroidIcon from "../../assets/common/svgs/Technology/Android.svg";
import ReactNativeIcon from "../../assets/common/svgs/Technology/React Native.svg";
import iOSIcon from "../../assets/common/svgs/Technology/iOS.svg";
import IonicIcon from "../../assets/common/svgs/Technology/Ionic.svg";
import FlutterIcon from "../../assets/common/svgs/Technology/Flutter.svg";
import PWAIcon from "../../assets/common/svgs/Technology/PWA.svg";

import WebIcon from "../../assets/common/svgs/TechCard.svg";
import ReactIcon from "../../assets/common/svgs/Technology/React.svg";
import AngularIcon from "../../assets/common/svgs/Technology/Angular.svg";
import GatsbyIcon from "../../assets/common/svgs/Technology/Gatsby.svg";
import VueIcon from "../../assets/common/svgs/Technology/Vue.svg";
import NextJsIcon from "../../assets/common/svgs/Technology/Next Js.svg";
import TypescriptIcon from "../../assets/common/svgs/Technology/Typescripts.svg";

import BackendIcon from "../../assets/common/svgs/Technology/backend.svg";
import NodeIcon from "../../assets/common/svgs/Technology/Node.svg";
import PythonIcon from "../../assets/common/svgs/Technology/Python.svg";
import DotNETIcon from "../../assets/common/svgs/Technology/DotNET.svg";
import PHPIcon from "../../assets/common/svgs/Technology/PHP.svg";
import LaravelIcon from "../../assets/common/svgs/Technology/Laravel.svg";
import QraphqlIcon from "../../assets/common/svgs/Technology/graphql.svg";
import MearnStackIcon from "../../assets/common/svgs/Technology/Mern-Stack 1.svg";
import FullStackIcon from "../../assets/common/svgs/Technology/FullStack.svg";
import MeanStackIcon from "../../assets/common/svgs/Technology/MeanStack 1.svg";
import DevOpsIcon from "../../assets/common/svgs/Technology/DevOpsdeveloper.svg";
import SoftwareDevIcon from "../../assets/common/svgs/Technology/SoftwareDev.svg";
import HirePageCard from "../../Components/HirePageCard";
import EngagementCard from "../../Components/EngagementCard";
import DEVELOPERIMG from "../../assets/common/svgs/developer.svg";

const ServiceDeveloper = () => {
  const [serviceData, setserviceData] = useState({
    DeviderTitle: "WHY IGEEK",
    Heading: "Why choose iGeek services?",
    Image: StaffAugmentationIMG,
    description:
      "We recognize that the success of your digital endeavors hinges on exceptional software development. Our expert developers are dedicated to delivering bespoke, scalable, and high-performance software solutions that address your unique requirements and drive your business forward.",
    title: "Our Product Development services",

    cards: [
      {
        Image: DEVELOPERIMG,
        title: "Agile Methodology",
        background: "bg-placebo-turquoise",
        description:
          "We employ agile development practices to promote adaptability, rapid iteration, and continuous improvement, ensuring your software remains relevant and competitive in the market.",
      },
      {
        Image: DEVELOPERIMG,
        title: "Cutting-Edge Technologies",
        background: "bg-clear-skies",
        description:
          "Leveraging the latest tools and technologies, our team delivers innovative software solutions that propel your digital transformation and differentiate your brand.",
      },
      {
        Image: DEVELOPERIMG,
        title: "Transparent Communication",
        background: "bg-placebo-turquoise",
        description:
          "Your success is our priority, and we strive to build lasting partnerships, providing ongoing support and consultation as your business grows and evolves.",
      },
      {
        Image: DEVELOPERIMG,
        title: "Long-Term Partnerships",
        background: "bg-clear-skies",
        description:
          "Our talented developers possess extensive technical expertise and industry experience, guaranteeing top-quality software solutions that exceed expectations.",
      },
      {
        Image: DEVELOPERIMG,
        title: "Experienced Developers",
        background: "bg-placebo-turquoise",
        description:
          "Our talented developers possess extensive technical expertise and industry experience, guaranteeing top-quality software solutions that exceed expectations.",
      },
    ],
  });

  const [Technolgys, setTechnolgys] = useState([
    {
      name: "Mobile",
      icon: MobileIcon,
      tech: [
        {
          icon: AndroidIcon,
          name: "Android",
        },
        {
          icon: ReactIcon,
          name: "React Native",
        },
        {
          icon: iOSIcon,
          name: "iOS",
        },
        {
          icon: IonicIcon,
          name: "Ionic",
        },
        {
          icon: FlutterIcon,
          name: "Flutter",
        },
      ],
    },
    {
      name: "Web",
      icon: WebIcon,
      tech: [
        {
          icon: ReactIcon,
          name: "React",
        },
        {
          icon: AngularIcon,
          name: "Angular",
        },
        {
          icon: GatsbyIcon,
          name: "Gatsby",
        },
        {
          icon: VueIcon,
          name: "Vue",
        },
        {
          icon: NextJsIcon,
          name: "Next Js",
        },
      ],
    },
    {
      name: "Backend",
      icon: BackendIcon,
      tech: [
        {
          icon: NodeIcon,
          name: "Node",
        },
        {
          icon: PythonIcon,
          name: "Python",
        },
        {
          icon: DotNETIcon,
          name: "ASP.Net",
        },
        {
          icon: PHPIcon,
          name: "php",
        },
        {
          icon: QraphqlIcon,
          name: "GraphQL",
        },
      ],
    },
    {
      name: "Others",
      icon: BackendIcon,
      tech: [
        {
          icon: MearnStackIcon,
          name: "MERN stack",
        },
        {
          icon: FullStackIcon,
          name: "Full stack",
        },
        {
          icon: MeanStackIcon,
          name: "MEAN stack",
        },
        {
          icon: DevOpsIcon,
          name: "Devops",
        },
        {
          icon: SoftwareDevIcon,
          name: "Software",
        },
      ],
    },
  ]);

  const [engaeData, setEngaeData] = useState([
    {
      title: "Fixed Pricing",
      description:
        "The fixed price model is suitable for clients who have a well-defined scope of work and clear project requirements, as it allows them to have a clear understanding of the total cost of the project upfront. The model provides predictability and stability to the client, and helps them to manage their budget and resources more effectively.",
      subTopics: [
        {
          desc: "Ideal for projects with clear requirements.",
        },
        {
          desc: "Budget predictability for clients.",
        },
        {
          desc: "Visualize your final product without spending on development.",
        },
      ],
    },
    {
      title: "Pay as you go",
      description:
        "Pay as you go is a pricing model that charges clients on an hourly basis for the work done by the IT service provider. In this model, the client and IT service provider agree on an hourly rate, and the client is charged for the actual hours worked by the provider on the project.",
      subTopics: [
        {
          desc: "Pay per hour spent by each department on your project.",
        },
        {
          desc: "Ideal for projects with constantly increasing requirements.",
        },
        {
          desc: "Maintain & support your existing product.",
        },
      ],
    },
    ,
  ]);

  return (
    <>
      <Layout>
        <div className="">
          <div className="relative mt-[100px] flex h-screen items-center justify-center pb-[350px]">
            <div className="absolute bottom-[-5px] flex h-[344px] w-full justify-center">
              <img
                draggable="false"
                className="h-full object-cover"
                src={HomeBottomBG1}
                alt=""
              />
            </div>

            <div className="flex flex-col items-center justify-center text-center">
              <div className="HeadingText mt-[100px] max-w-[980px] max_md:px-[50px] max_sm:px-[20px]">
                Software Craftsmanship: Your Vision, Our Expertise, Infinite
                Possibilities.
              </div>
              <div className="SubHeadingText mt-[40px] max-w-[858px] max_md:px-[50px] max_sm:px-[20px]">
                Experience unparalleled software development services tailored
                to your unique vision. Our professionals combine cutting-edge
                technology and expertise to turn your ideas into powerful
                digital solutions.
              </div>
            </div>
          </div>

          <div className="m-auto w-full max-w-[1240px] max_xl:max-w-[940px] max_md:px-[50px] max_sm:px-[20px]">
            <ServiceSectionComp
              maxWidth={"max-w-[396px]"}
              serviceData={serviceData}
            />

            {/* WORK */}
            <div className="my-[160px] max_md:my-[80px]">
              <DeviderLine title="HIRE" right="right-[50%]" />

              <div className="my-[60px] flex flex-col items-center justify-center max_sm:my-[50px]">
                <div
                  className={`w-full max-w-[243px] text-center text-ExLargeSize font-bold text-duskwood `}
                >
                  Hire Developer
                </div>
              </div>

              <div className="flex h-full w-full flex-wrap items-center justify-between gap-[20px] max_xl:gap-[16px] max_md:justify-center">
                {Technolgys.map((itm, index) => (
                  <Fragment key={index}>
                    <HirePageCard title={itm.name} tech={itm.tech} />
                  </Fragment>
                ))}
              </div>
            </div>

            {/* ENGAGEMENT */}
            <div className="pb-[20px]">
              <DeviderLine title="ENGAGEMENT" right="right-[75%]" />

              <div className="my-[66px] flex flex-col items-center justify-center">
                <div
                  className={`w-full max-w-[380px] text-center text-ExLargeSize font-bold text-duskwood `}
                >
                  Client Engagement Frameworks
                </div>
              </div>

              <div className="flex flex-wrap justify-center gap-[20px]">
                {engaeData.map((itm, index) => (
                  <Fragment key={index}>
                    <EngagementCard
                      title={itm.title}
                      description={itm.description}
                      subTopics={itm.subTopics}
                    />
                  </Fragment>
                ))}
                {/* <EngagementCard /> */}
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default ServiceDeveloper;
